@import "src/styles/utils.scss";

$color-white: #ffffff;
$color-black: #000000;
$color-green: #00ff00;
$color-red: #ff0000;
$color-white-translucent: rgba(255,255,255,.54);
$color-white-translucent-light: rgba(255,255,255,.1);
$color-green-translucent: rgba(0,255,0, .54);

$text-normal: c(14px);
$text-small: c(10px);
$text-weight-bold: 600;
$text-weight-normal: 300;

$spacing-normal: c(16px);
$spacing-small: c(8px);
$spacing-tiny: c(4px);
$spacing-med: c(32px);
$spacing-large: c(64px);
$spacing-huge: c(256px);

$letter-spacing: c(2px);

$v100: calc(100vh - calc(100vh - 100%));

@mixin text_normal_white {
    color: $color-white;
    font-size: $text-normal;
}

@mixin flex_vertical_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@mixin flex_horizontal_center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
html {
    height: $v100;
}
body {
    background-color: $color-black;
    width: 100vw;
    height: $v100;
    color: $color-white;
}

.App {
    width: 100vw;
    height: $v100;
    * {
        -webkit-touch-callout:none;
        -webkit-user-select:none;
        -moz-user-select:none;
        user-select: none;
    }
}


.flexVertical {
    @include flex_vertical_center;
}
.flexHorizontal {
    @include flex_horizontal_center;
}

.buttonHolder {
    width: $spacing-huge;
    height: $spacing-huge;
    position: relative;
}

.buttonHolderActive {
    width: $spacing-huge;
    height: $spacing-huge;
    border-radius: $spacing-huge;
    position: absolute;
    top: 0;
    background-color: $color-green-translucent;
    z-index: 0;
    animation: scaleIn 1.5s infinite cubic-bezier(.36, .11, .89, .32);
}
.buttonHolderUnderlay {
    width: $spacing-huge;
    height: $spacing-huge;
    border-radius: $spacing-huge;
    position: absolute;
    top: 0;
    background-color: $color-black;
    z-index: 1;
}
@keyframes scaleIn {
    0% {
      transform: scale(.6, .6);
      opacity: 0;
    }
    40% {
        transform: scale(1.1, 1.1);
        opacity: .5;
      }
    100% {
      transform: scale(1.6, 1.6);
      opacity: 0;
    }
}

button {
    font-size: $text-small;
    font-weight: $text-weight-bold;
    letter-spacing: $letter-spacing;
}

button.pushToTalk {
    position: absolute;
    top: 0;
    z-index: 2;
    width: $spacing-huge;
    height: $spacing-huge;
    border-radius: $spacing-huge;
    border: $spacing-tiny solid $color-white;
    background-color: $color-white-translucent;
    @include flex_vertical_center();
    outline: $spacing-small solid $color-white-translucent-light;
    transition: .3s all;
}

//pushButton
button.pushOff {
    background-color: $color-white-translucent-light;
    border: $spacing-tiny solid $color-white-translucent-light;
    color: $color-white;
}
button.pushBlocked {
    background-color: $color-white-translucent-light;
    color: $color-white-translucent;
    border: $spacing-tiny solid $color-red;
}
button.pushActive {
    background-color: $color-white-translucent-light;
    color: $color-green;
    border: $spacing-tiny solid $color-green;
    animation: pushActiveAnimation 2s ease-in-out infinite alternate;

}

@keyframes pushActiveAnimation {
    from {
        border: $spacing-tiny solid $color-green;
    }
    to {
        border: $spacing-tiny solid $color-green-translucent;
    }
}


//Switch button
.switchButton {
    padding: $spacing-tiny;
    display: flex;
    flex-direction: row;
    width: $spacing-large;
    align-items: center;
    justify-content: space-between;
    border-radius: $spacing-med;
    border: 4px solid transparent;
    background-color: $color-white-translucent-light;
    transition: .3s all;
    * {
        transition: .3s all;
    }
}
.switchButtonActive {
    background-color: $color-white-translucent-light;
    border: 4px solid $color-green;
}
.switchCircle {
    width: $spacing-med;
    height: $spacing-med;
    border-radius: $spacing-med;
    background-color: $color-white;
    transition: .3s all;
    margin-left: 0;
}
.switchCircleOn {
    margin-left: auto;
    transition: .3s all;
    background-color: $color-green;
}

div#root {
    height: $v100;
}

//layout

.layout {
    height: $v100;
    width: 100%;
    @include flex_vertical_center();
    justify-content: space-between;
}

.layoutHeaderBar {
    display: flex;
    flex-direction: row;
    width: calc(100% - $spacing-normal);
    align-items: center;
    padding: $spacing-small;

}

.layoutFlex {
    flex: 1;
}

.layoutCenterBlock {
    display: flex;
    flex-direction: column;
    align-items: center;

}


//transcriptions 
.transcriptionLog {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $text-small;
    font-weight: $text-weight-bold;
    margin-top: $spacing-med;
    color: $color-white-translucent;
    font-style: italic;
    padding: $spacing-normal;
}

//participants 

.participant {
    font-size: $text-small;
    font-weight: $text-weight-bold;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.participantList {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.participantStatus {
    // width: $spacing-small;
    // height: $spacing-small;
    // border-radius: $spacing-small;
    // background-color: $color-green;
    margin-right: $spacing-tiny;
}

.participantSpeaking {
    animation: speakingFade .3s ease-in-out infinite alternate}

@keyframes speakingFade {
    from {opacity: 1;}
    to {opacity: .33;}
}


//transcribeWidget 
.transcribeWidget {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $text-small;
    font-weight: $text-weight-bold;
}



.whereby {
    width: 100vw;
    
    max-width: 800px;
    height: 600px;
}

.wherebyHolder {
    border: 1px solid $color-white-translucent-light;
    padding: $spacing-small;
    border-radius: $spacing-normal;
    margin-top: $spacing-med;
}

iframe {
    border: 0px solid transparent;
    outline: 0px solid transparent;
    border-radius: $spacing-med;
}